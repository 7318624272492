import React from "react";
import "../Home/Home.css";
import Origin from "../Origin/Origin";
import Mv from "../M&V/Mv";
import Featuredcard from "../FPCard/Featuredcard";
import PCategory from "../PCategory/PCategory";
import Team from "../OurTeam/Team";
import Strategy from "../Strategy/Strategy";
import Review from "../Review/Review";
import Counter from "../Counter/Counter";
import {Link} from 'react-router-dom'
const Home = () => {
  return (
    <>
    <div className="full-home">
      <section class="home">
        <div class="overlay-text">
          <h1 class="heading" data-aos="fade-down">We Care For Your Health</h1>
          <p class="paragraph" data-aos="fade-down">
            At Medix, your health is our top priority. Our expert team is
            dedicated to delivering personalized, compassionate healthcare
            solutions. From preventive services to specialized treatments, we
            support you at every stage. Trust us to ensure your well-being,
            because we care for your health.
          </p>
          <Link to="/contact">
          <button className="button-42" data-aos="fade-down">Connect US</button>
          </Link>
        </div>
      </section>

      <Origin />
      <Featuredcard />
      <PCategory />
      <Mv />
     
      <Team />
      <Review />
      <Counter />
      </div>
    </>
  );
};

export default Home;
