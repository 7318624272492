import React, { useState, useEffect } from 'react';
import '../M&V/Mv.css';

const Mv = () => {
  const images = [
    "https://img.freepik.com/free-vector/medics-working-charts_1262-19836.jpg?t=st=1717496982~exp=1717500582~hmac=f2aa7e2809a1ab5eb378f8f8fcb88b94e1c65b1db275b31e6d89e58731df77cd&w=900",
    "https://img.freepik.com/free-vector/medical-infographic-template_23-2148457165.jpg?t=st=1717497114~exp=1717500714~hmac=dbdfd059bf0c5d726c6cfb64371f01a6255749cedd12f6f4743ebfeb4fedf5f6&w=900",
    "https://img.freepik.com/free-photo/medical-banner-with-doctor-patient_23-2149611238.jpg?t=st=1717497157~exp=1717500757~hmac=2a43321d2ecf891a070cfc8308c54b02039d00256d5ff3dfc20f7d0e43c9242d&w=900"
  ];

  const images2 = [
    "https://iaam.se/img/Our%20Vision%20&%20Mission/Pic%201%20-%20640%20X%20426px%20Vision.jpg",
    "https://img.freepik.com/free-photo/medium-shot-doctor-wearing-mask-indoors_23-2148816202.jpg?t=st=1735020259~exp=1735023859~hmac=a177b2d7eaf148e402273ce505616685f7cfb511a04ea2b912ea27cf47c590ae&w=740",
    "https://www.chilawhospital.health.gov.lk/images/4ecd4206-81ea-4b89-874b-7f34a5a799c5_source__v.png"
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <>
      <div className="about">
        {/* ------------------------------------ */}
        <div className="upper">
        <div className="slider" id="lower-img">
            <img src={images2[currentIndex]} data-aos="zoom-out-right" alt="Image" id="slider" />
          </div>
        <div className="info" id="lower-info">
            <h1 data-aos="flip-up">OUR VISION</h1>
            <p data-aos="fade-down">
            Medix aspires to be a leading medical agency, recognized for our unwavering commitment to quality care, innovation, and patient well-being. We achieve this by continuously innovating and utilizing cutting-edge technology, implementing best practices in healthcare management, and fostering a culture of learning and development for our staff. We advocate for preventative healthcare and wellness programs, championing ethical and transparent practices within the industry. Through these efforts, we strive to bridge the gap between patients and exceptional care.
            </p>
          </div>
          
        </div>
        {/* ------------------------------------ */}
        <div className="lower">
          <div className="info" id="lower-info">
            <h1 data-aos="flip-up">OUR MISSION</h1>
            <p data-aos="fade-down">
            Medix is dedicated to providing exceptional medical services and resources to our clients. We believe in fostering a healthier and happier community by building strong partnerships with healthcare providers, offering a comprehensive range of medical solutions, and employing a team of dedicated and qualified professionals. Personalized and compassionate care is paramount, while affordability and accessibility remain key priorities.
            </p>
          </div>
          <div className="slider" id="lower-img">
            <img src={images[currentIndex]} data-aos="zoom-out-left" alt="Image" id="slider" />
          </div>
        </div>
        {/* ------------------------------------ */}
      </div>
    </>
  );
}

export default Mv;
