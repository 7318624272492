import React from "react";
import "../Origin/Origin.css";

const Origin = () => {
  return (
    <>
      <div id="container">
        <aside id="left-sidebar" data-aos="zoom-out-right">
          <p id="intro">
            Over 4 years of <span>medicine experience</span>
          </p>
          <img
            src="https://img.freepik.com/free-photo/close-up-health-worker_23-2149112572.jpg?t=st=1717142696~exp=1717146296~hmac=b22a3e77cf89e150f7f8e409b32a23e711a33fdca3499ead93dfd1cadd6f48dc&w=360"
            alt="picture of a person"
          />
          <div id="work-log">
            <h2>Founder</h2>
            <ul>
              <li>Medix PVT LTD</li>
              <li>Text</li>
              <li>XYZ Text™</li>
            </ul>
          </div>
        </aside>
        <main data-aos="zoom-out-down">
          <h1>
            Medix <span>A story of Medix Origin</span>
          </h1>
          <article>
            <img
              id="featured-img"
              src="https://img.freepik.com/premium-photo/science-biology-ecology-research-people-concept-close-up-young-female-scientist-holding-petri-dish-with-plant-soil-sample-bio-laboratory_380164-87398.jpg?w=900"
              alt="Article's featured image"
            />
            
          </article>
        </main>
        <aside id="right-sidebar" data-aos="zoom-out-left">
          <img
            src="https://img.freepik.com/premium-photo/packings-pills-capsules-medicines-blue-table_899504-1304.jpg?w=360"
            alt="tall cover photo"
          />
        </aside>
       
      </div>
      <div className="text-full">
      <p className="text-full-p" data-aos="fade-down">
      In the bustling city of Metropolis, Dr. XYZ embarked on a journey to redefine healthcare. Born from humble beginnings, he possessed an insatiable curiosity for science and medicine, fueled by the values instilled in him by hardworking parents. Armed with determination and compassion, Dr. XYZ founded Medix, a beacon of hope in the medical industry. Despite facing skepticism and limited resources, he and his team persevered, pioneering groundbreaking treatments for rare diseases.
            </p>
            {/*--
            <p className="text-full-p">
            Medix's early triumphs were marked by the development of life-saving therapies, earning Dr. XYZ and his team widespread acclaim. Their relentless pursuit of excellence and commitment to patient care attracted investors and philanthropists, propelling Medix to the forefront of medical innovation. With each breakthrough, Medix's reputation grew, becoming synonymous with compassion, innovation, and hope.
            </p>
            <p className="text-full-p">
            Through Dr. XYZ's leadership, Medix continued to thrive, expanding its reach and transforming healthcare on a global scale. From cutting-edge cancer therapies to revolutionary surgical techniques, Medix's contributions to the medical field were unparalleled. Yet, amidst the accolades, Dr. XYZ remained grounded, guided by the belief that true success lies in the lives touched and the patients given a second chance. Today, Medix stands as a testament to his vision, inspiring generations to dare to dream and redefine what is possible in healthcare.
            </p>

            -*/}
           
           
      </div>
    </>
  );
};

export default Origin;
