import React from 'react'
import '../PCategory/PCategory.css'
const PCategory = () => {
  return (
    <>
    <div className='main-pc'>
    <div class="ribbon2" data-aos="fade-left">Popular Categories</div>
    <div className='category-body'>
   
<div className="category">
  <div className="item" data-aos="fade-right">
    <span className="title">Supplement</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/free-photo/medicine-bottles-tablets-wooden-desk_1387-320.jpg?t=st=1717501818~exp=1717505418~hmac=fdecb7726e948d497f8b22b6a2a40bb4d19bc313e7fa9f315f2f3336178e2363&w=900" alt />
  </div>
  <div className="item" data-aos="fade-left">
    <span className="title">Diabetes</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/premium-photo/word-diabetes-with-syringe-pills-device-measuring-blood-sugar_157402-3344.jpg?w=900" alt />
  </div>
  <div className="item" data-aos="fade-right">
    <span className="title">First Aid</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/free-photo/front-view-composition-medical-still-life-elements_23-2148854049.jpg?t=st=1717501924~exp=1717505524~hmac=2e05ded3ffc9b6248141f8a130bdf13175d8a9101c4625328e31da2040f7f1db&w=900" alt />
  </div>
  <div className="item" data-aos="fade-left">
    <span className="title">Heart</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/free-photo/view-anatomic-heart-model-educational-purpose-with-magnifying-glass_23-2149894501.jpg?t=st=1717501978~exp=1717505578~hmac=0bc230f8197a71c7af2494cec42347bd9bcf5f40a926695323f22f13ce306d21&w=900" alt />
  </div>
  <div className="item" data-aos="fade-right">
    <span className="title">Limbs & Joints</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/free-photo/athletic-man-practicing-sport-outdoor_23-2148196830.jpg?t=st=1717502026~exp=1717505626~hmac=0910843dd67a21873c035645445816b25f04b44a42d1112b8295479e5e7d5cd3&w=900" alt />
  </div>
  <div className="item" data-aos="fade-left">
    <span className="title">Eye</span>
    <span className="link" href="#">Check Out</span>
    <img className="img1" src="https://img.freepik.com/premium-photo/3d-anatomical-model-eye_183364-119512.jpg?w=1060" alt />
  </div>
</div>
</div>
</div>
    </>
  )
}

export default PCategory