import React from 'react'
import '../FPCard/Featuredcard.css'
const Featuredcard = () => {
  return (
    <>
<div className='main-fc'>
 <div class="ribbon" data-aos="fade-right">Featured Products</div>
  <main className="main bd-grid">
    <article className="cardf" data-aos="zoom-in">
      <div className="card__img">
        <img src="fp1.png" alt />
      </div>
      <div className="card__name">
        <p>MEDICINE NAME</p>
      </div>
      <div className="card__precis">
        <a href className="card__icon"><ion-icon name="heart-outline" /></a>
        <div>
          <span className="card__preci card__preci--before">₹90.00</span>
          <span className="card__preci card__preci--now">₹49.00</span>
        </div>
        <a href className="card__icon"><ion-icon name="cart-outline" /></a>
      </div>
    </article>
    <article className="cardf" data-aos="zoom-in">
      <div className="card__img">
        <img src="fp1.png" alt />
      </div>
      <div className="card__name">
        <p>MEDICINE NAME</p>
      </div>
      <div className="card__precis">
        <a href className="card__icon"><ion-icon name="heart-outline" /></a>
        <div>
          <span className="card__preci card__preci--before">₹90.00</span>
          <span className="card__preci card__preci--now">₹49.00</span>
        </div>
        <a href className="card__icon"><ion-icon name="cart-outline" /></a>
      </div>
    </article>
    <article className="cardf" data-aos="zoom-in">
      <div className="card__img">
        <img src="fp1.png" alt />
      </div>
      <div className="card__name">
        <p>MEDICINE NAME</p>
      </div>
      <div className="card__precis">
        <a href className="card__icon"><ion-icon name="heart-outline" /></a>
        <div>
          <span className="card__preci card__preci--before">₹90.00</span>
          <span className="card__preci card__preci--now">₹49.00</span>
        </div>
        <a href className="card__icon"><ion-icon name="cart-outline" /></a>
      </div>
    </article>
    <article className="cardf" data-aos="zoom-in">
      <div className="card__img">
        <img src="fp1.png" alt />
      </div>
      <div className="card__name">
        <p>MEDICINE NAME</p>
      </div>
      <div className="card__precis">
        <a href className="card__icon"><ion-icon name="heart-outline" /></a>
        <div>
          <span className="card__preci card__preci--before">₹90.00</span>
          <span className="card__preci card__preci--now">₹49.00</span>
        </div>
        <a href className="card__icon"><ion-icon name="cart-outline" /></a>
      </div>
    </article>
  </main>
  
 
</div>


    </>
  )
}

export default Featuredcard