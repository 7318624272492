import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import '../Review/Review.css'
const Review = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
    <div className='main-review'>
    <div class="ribbon4" data-aos="fade-left">Our Clients Says</div>
     <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className='box-main'>
         <div className="testimonial-box">
      {/*top-----------------------*/}
      <div className="box-top">
        {/*profile---*/}
        <div className="profile">
          {/*img--*/}
          <div className="profile-img">
            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
          </div>
          {/*name-and-username*/}
          <div className="name-user">
            <strong>Liam mendes</strong>
            <span>@liammendes</span>
          </div>
        </div>
        {/*reviews----*/}
        <div className="reviews">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="far fa-star" />{/*Empty star*/}
        </div>
      </div>
      {/*Comments--------------------------------------*/}
      <div className="client-comment">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
      </div>
    </div>
    <div className="testimonial-box">
      {/*top-----------------------*/}
      <div className="box-top">
        {/*profile---*/}
        <div className="profile">
          {/*img--*/}
          <div className="profile-img">
            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
          </div>
          {/*name-and-username*/}
          <div className="name-user">
            <strong>Noah Wood</strong>
            <span>@noahwood</span>
          </div>
        </div>
        {/*reviews----*/}
        <div className="reviews">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />{/*Empty star*/}
        </div>
      </div>
      {/*Comments--------------------------------------*/}
      <div className="client-comment">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
      </div>
    </div>
    </div>
    </SwiperSlide>
        <SwiperSlide>
          <div className='box-main'>
          <div className="testimonial-box">
      {/*top-----------------------*/}
      <div className="box-top">
        {/*profile---*/}
        <div className="profile">
          {/*img--*/}
          <div className="profile-img">
            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
          </div>
          {/*name-and-username*/}
          <div className="name-user">
            <strong>Oliver Queen</strong>
            <span>@oliverqueen</span>
          </div>
        </div>
        {/*reviews----*/}
        <div className="reviews">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="far fa-star" />{/*Empty star*/}
        </div>
      </div>
      {/*Comments--------------------------------------*/}
      <div className="client-comment">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
      </div>
    </div>
    {/*BOX-4------------*/}
    <div className="testimonial-box">
      {/*top-----------------------*/}
      <div className="box-top">
        {/*profile---*/}
        <div className="profile">
          {/*img--*/}
          <div className="profile-img">
            <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
          </div>
          {/*name-and-username*/}
          <div className="name-user">
            <strong>Barry Allen</strong>
            <span>@barryallen</span>
          </div>
        </div>
        {/*reviews----*/}
        <div className="reviews">
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="fas fa-star" />
          <i className="far fa-star" />{/*Empty star*/}
        </div>
      </div>
      {/*Comments--------------------------------------*/}
      <div className="client-comment">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
      </div>
    </div>
          </div>
        </SwiperSlide>
        
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
   
</div>
    </>
  )
}

export default Review